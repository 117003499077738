export const FONT_SIZE_SMALL = '12px';
export const FONT_SIZE_MEDIUM = '16px';
export const FONT_SIZE_LARGE = '20px';
export const FONT_SIZE_XLARGE = '24px';
export const FONT_SIZE_XXLARGE = '28px';
export const FONT_SIZE_XXXLARGE = '32px';
export const FONT_SIZE_XXXXLARGE = '40px';

export const FONT_FAMILY = 'GtAmerica';

export const FONT_STYLES_NORMAL = 'normal';
export const FONT_STYLES_BOLD = 'bold';
export const FONT_STYLES_ITALIC = 'italic';
export const FONT_WEIGHT_LIGHT = '300';
export const FONT_WEIGHT_MEDIUM = '500';
export const FONT_WEIGHT_BOLD = '700';
export const FONT_WEIGHT_EXTRA_BOLD = '800';
export const FONT_WEIGHT_BLACK = '900';
export const FONT_WEIGHT_THIN = '100';
export const FONT_WEIGHT_EXTRA_LIGHT = '200';
export const FONT_WEIGHT_ULTRALIGHT = '200';
export const FONT_WEIGHT_REGULAR = '400';
export const FONT_WEIGHT_SEMIBOLD = '600';
export const FONT_WEIGHT_DEMIBOLD = '600';
export const FONT_WEIGHT_MEDIUM_BOLD = '600';
