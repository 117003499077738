import React, { createContext, useCallback, useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BusReserver } from '../components/Home/interface';
import { ICoparticipationSolicitation } from '../components/PersonalInfo/CenterComponent/interface';
import { AuthContextData } from '../interface/context/AuthContext';
import {
  RestaurantReserv,
  TrasportReservsType,
} from '../interface/context/ReservCheck.interface';
import {
  AuthState,
  BuildingState,
  FloorState,
  MealState,
  PlacingValues,
  ReserveState,
  RoomState,
  TableState,
  UnitySelect,
  UserEmployeeIDS,
  UserMensagingState,
} from '../interface/global/AuthInterface';
import { ICardCode } from '../interface/global/carcCode';
import hourArray from '../interface/global/HoursArray';
import { ParkingReserveState } from '../interface/global/TransportInterface';
import api from '../services/api';
import { AdministratorProvider } from './Administrator';
import { ReservesProvider } from './Reserves';
import { RestaurantProvider } from './RestaurantContext';
import { TransportProvider } from './TransportContext';
import { BusControllerProvider } from './BusControllerContext';

const queryClient = new QueryClient();

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

const OnePlacePhoto = '@OnePlace: photo';
const OnePlaceMealData = '@OnePlace: mealData';
const OnePlaceBuildingID = '@OnePlace: buildingID';
const OnePlaceFloorID = '@OnePlace: floorID';
const OnePlaceRoomID = '@OnePlace: roomID';
const OnePlaceTableID = '@OnePlace: tableID';
const OnePlaceBusReserveData = '@OnePlace: busReserveData';
const OnePlaceParkingReserveData = '@OnePlace: parkingDataTxa';
const OnePlaceUnitys = '@OnePlace: unitys';

export const AuthProvider: React.FC = ({ children }) => {
  const [groupsApiData, setGroupsApiData] = useState<boolean>(() => {
    const data = localStorage.getItem('@OnePlace: groupIsEnabled');

    if (data) {
      return JSON.parse(data);
    }

    return false as boolean;
  });

  const setGroupsApi = useCallback(async (data: boolean) => {
    localStorage.setItem('@OnePlace: groupIsEnabled', JSON.stringify(data));

    setGroupsApiData(data);
  }, []);

  const [unityRestaurantData, setUnityRestaurantData] = useState<UnitySelect>(
    () => {
      const data = localStorage.getItem('@OnePlace: unityIDRestaurant');

      if (data) {
        return JSON.parse(data);
      }

      return {} as UnitySelect;
    }
  );

  const [unitysData, setUnitysData] = useState<UnitySelect[]>(() => {
    const data = localStorage.getItem(OnePlaceUnitys);

    if (data) {
      return JSON.parse(data);
    }

    return [] as UnitySelect[];
  });

  const [fixWorkstationData, setFixWorkstationData] = useState<boolean>(false);

  const setFixWorkstation = useCallback(async (fixWorkstation) => {
    setFixWorkstationData(fixWorkstation);
  }, []);

  const [codeCajuData, setCodeCajuData] = useState<ICardCode>(() => {
    const CardCode = localStorage.getItem('@OnePlace: cardCode');

    if (CardCode) {
      return JSON.parse(CardCode);
    }

    return {} as ICardCode;
  });

  const setCodeCaju = useCallback(async (data: ICardCode) => {
    localStorage.setItem('@OnePlace: cardCode', JSON.stringify(data));
    setCodeCajuData(data);
  }, []);

  const [unityWorkstationData, setUnityWorkstationData] = useState<UnitySelect>(
    () => {
      const data = localStorage.getItem('@OnePlace: unityIDWorkStation');

      if (data) {
        return JSON.parse(data);
      }

      return {} as UnitySelect;
    }
  );

  const [unityTransportData, setUnityTransportData] = useState<UnitySelect>(
    () => {
      const data = localStorage.getItem('@OnePlace: unityIDTransport');

      if (data) {
        return JSON.parse(data);
      }

      return {} as UnitySelect;
    }
  );

  const [unityData, setUnityData] = useState<UnitySelect>();

  const [startHourData, setStartHourData] = useState<Date>(() => {
    const time = localStorage.getItem('@OnePlace: startHourData');

    if (time) {
      return new Date(JSON.parse(time));
    }

    return new Date();
  });

  const [endHourData, setEndHourData] = useState<Date>(() => {
    const time = localStorage.getItem('@OnePlace: endHourData');
    if (time) {
      return new Date(JSON.parse(time));
    }

    return new Date();
  });

  const isDesktopData = useMediaQuery({ query: '(min-width: 900px)' });

  const [userMessagingPush, setUserMessagingPush] =
    useState<UserMensagingState>(() => {
      const session = localStorage.getItem('@OnePlace: MessagingPush');

      if (session) {
        return { session };
      }

      return {} as UserMensagingState;
    });

  const [photoData, setPhotoData] = useState<string>(() => {
    const photo = localStorage.getItem(OnePlacePhoto);

    return photo as string;
  });

  const [userData, setUserData] = useState<UserEmployeeIDS>(() => {
    const userLogin = localStorage.getItem('@OnePlace: userIDS');
    const userDataLocal = JSON.parse(userLogin || '{}');
    return { ...userDataLocal, ...userData } as UserEmployeeIDS;
  });

  const [auth, setIsAuth] = useState<AuthState>(() => {
    const isAuth = localStorage.getItem('@OnePlace: isAuth');

    return { isAuth } as AuthState;
  });

  const [reserveData, setReserveData] = useState<ReserveState>(() => {
    const id = localStorage.getItem('@OnePlace: reserveID');

    if (id) {
      return { id };
    }

    return {} as ReserveState;
  });

  const [reservedBusData, setReservedBusData] = useState<boolean>(false);

  const setReservedBus = useCallback(async (reservedBus) => {
    setReservedBusData(reservedBus);
  }, []);

  const [mealData, setMealData] = useState<MealState>(() => {
    const meal = localStorage.getItem(OnePlaceMealData);

    if (meal) {
      return { meal: JSON.parse(meal) };
    }

    return {} as MealState;
  });

  const [buldingData, setBuildingData] = useState<BuildingState>(() => {
    const id = localStorage.getItem(OnePlaceBuildingID);

    if (id) {
      return { id: JSON.parse(id) };
    }

    return {} as BuildingState;
  });

  const [placingData, setPlacingData] = useState<PlacingValues>(() => {
    const placing = localStorage.getItem('@OnePlace: placing');

    if (placing) {
      return { placing: JSON.parse(placing) };
    }

    return { placing: 'users' } as PlacingValues;
  });

  const [floorData, setFloorData] = useState<FloorState>(() => {
    const id = localStorage.getItem(OnePlaceFloorID);

    if (id) {
      return { id };
    }

    return {} as FloorState;
  });

  const [roomData, setRoomData] = useState<RoomState>(() => {
    const id = localStorage.getItem(OnePlaceRoomID);

    if (id) {
      return { id };
    }

    return {} as RoomState;
  });

  const [tableData, setTableData] = useState<TableState>(() => {
    const id = localStorage.getItem(OnePlaceTableID);

    if (id) {
      return { id };
    }

    return {} as TableState;
  });

  const [parkingReserveData, setParkingReserveData] =
    useState<ParkingReserveState>(() => {
      const parkingReserve = localStorage.getItem(OnePlaceParkingReserveData);

      if (parkingReserve) {
        const reserve: ParkingReserveState = JSON.parse(parkingReserve);
        return reserve;
      }
      return {} as ParkingReserveState;
    });

  const [busReserveData, setBusReserveData] = useState<TrasportReservsType>(
    () => {
      const busReserve = localStorage.getItem(OnePlaceBusReserveData);

      if (busReserve) {
        const reserve: TrasportReservsType = JSON.parse(busReserve);
        return reserve;
      }
      return {} as TrasportReservsType;
    }
  );

  const checkMessagingPush = useCallback(async (session) => {
    localStorage.setItem('@OnePlace: MessagingPush', session);

    setUserMessagingPush({ session });
  }, []);

  const [transportIsGuest, setTransportIsGuest] = useState(false);

  const setPhoto = useCallback(async (photo: string) => {
    localStorage.setItem(OnePlacePhoto, photo);
    setPhotoData(photo);
  }, []);

  const checkUser = useCallback(async (data: UserEmployeeIDS) => {
    localStorage.setItem('@OnePlace: userData', data.login);
    localStorage.setItem('@OnePlace: userLogin', data.login);
    localStorage.setItem('@OnePlace: userIDS', JSON.stringify(data));
    localStorage.setItem('@OnePlace: firstAccess', data.firstAccess);
    setUserData(data);

    try {
      const response = await api.get<{
        photo: string;
      }>(`user/photo`);

      localStorage.setItem(OnePlacePhoto, response.data.photo);
      setPhotoData(response.data.photo);
    } catch {
      localStorage.removeItem(OnePlacePhoto);
      setPhotoData('');
    }

    await api
      .get<ICardCode>(`cardCode`)
      .then((res) => {
        if (res.data.cardCode) setCodeCaju(res.data);
      })
      .catch(() => {
        setCodeCaju({} as ICardCode);
      });
  }, []);

  const checkAuth = useCallback(async (isAuth) => {
    localStorage.setItem('@OnePlace: isAuth', isAuth);

    setIsAuth({ isAuth });
  }, []);

  const [nameData, setNameData] = useState<string>(() => {
    const isAuth = localStorage.getItem('@OnePlace: name');
    if (isAuth) {
      return isAuth as string;
    }
    return '';
  });

  const checkName = useCallback(async (isAuth) => {
    localStorage.setItem('@OnePlace: name', isAuth);

    setNameData(isAuth);
  }, []);

  const checkStartHour = useCallback(
    async (time) => {
      const start = new Date(startHourData);
      if (time.hour) {
        start.setHours(
          parseInt(time.hour.substr(0, 2), 10),
          parseInt(time.hour.substr(3, 4), 10),
          0,
          0
        );
      }
      if (time.date) {
        start.setDate(time.date.getDate());
        start.setMonth(time.date.getMonth());
        start.setFullYear(time.date.getFullYear());
      }

      localStorage.setItem('@OnePlace: startHourData', JSON.stringify(start));
      setStartHourData(start);
    },
    [startHourData]
  );

  const checkEndHour = useCallback(
    async (time) => {
      const end = new Date(endHourData);
      if (time.hour) {
        end.setHours(
          parseInt(time.hour.substr(0, 2), 10),
          parseInt(time.hour.substr(3, 4), 10),
          0,
          0
        );
      }
      if (time.date) {
        end.setDate(time.date.getDate());
        end.setMonth(time.date.getMonth());
        end.setFullYear(time.date.getFullYear());
      }

      localStorage.setItem('@OnePlace: endHourData', JSON.stringify(end));
      setEndHourData(end);
    },
    [endHourData]
  );

  const [userIdData, setUserIdData] = useState<string>(() => {
    const UserID = localStorage.getItem('@OnePlace: userID');

    if (UserID) {
      return JSON.parse(UserID);
    }

    return {} as MealState;
  });

  const checkUserId = useCallback(async (UserID) => {
    localStorage.setItem('@OnePlace: userID', JSON.stringify(UserID));

    setUserIdData(UserID);
  }, []);

  const checkMeal = useCallback(async (meal) => {
    localStorage.setItem(OnePlaceMealData, JSON.stringify(meal));

    setMealData({ meal });
  }, []);

  const checkPlacing = useCallback(async (placing) => {
    localStorage.setItem('@OnePlace: placing', JSON.stringify(placing));

    setPlacingData({ placing });
  }, []);

  const checkReserve = useCallback(async (id) => {
    localStorage.setItem('@OnePlace: reserveID', id);

    setReserveData({ id });
  }, []);

  const [reserveRestaurantData, setReserveRestaurantData] =
    useState<RestaurantReserv>();

  const checkRestaurantReserv = useCallback(async (data) => {
    setReserveRestaurantData(data);
  }, []);

  const checkUnityRestaurant = useCallback(async (data: UnitySelect) => {
    localStorage.setItem('@OnePlace: unityIDRestaurant', JSON.stringify(data));

    setUnityRestaurantData(data);
  }, []);

  const checkUnityWorkstation = useCallback(async (data: UnitySelect) => {
    localStorage.setItem('@OnePlace: unityIDWorkstation', JSON.stringify(data));

    setUnityWorkstationData(data);
  }, []);

  const checkUnityTransport = useCallback(async (data: UnitySelect) => {
    localStorage.setItem('@OnePlace: unityIDTransport', JSON.stringify(data));

    setUnityTransportData(data);
  }, []);

  const checkUnitys = useCallback(async (data: UnitySelect[]) => {
    setUnitysData(data);
    localStorage.setItem(OnePlaceUnitys, JSON.stringify(data));
  }, []);

  const checkUnity = useCallback(async (data: UnitySelect) => {
    setUnityData(data);
    checkUnityRestaurant(data);
    checkUnityWorkstation(data);
    checkUnityTransport(data);
  }, []);

  const checkBuilding = useCallback(
    async ({ name }) => {
      localStorage.setItem(OnePlaceBuildingID, JSON.stringify(name));

      setBuildingData({ id: name });
    },
    [unityWorkstationData.value]
  );

  const checkFloor = useCallback(async (floor) => {
    localStorage.setItem(OnePlaceFloorID, floor);
    setFloorData({ id: floor });
  }, []);

  const checkRoom = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ _, floarData }) => {
      const response = await api.get<{
        id: string;
      }>(`room/getRooms/${buldingData.id}/${floarData}`);
      const { id } = response.data[0];
      localStorage.setItem(OnePlaceRoomID, id);

      setRoomData({ id });
    },
    [buldingData.id]
  );

  const checkTable = useCallback(async ({ numberOfTable }) => {
    localStorage.setItem(OnePlaceTableID, numberOfTable);
    setTableData({ id: numberOfTable });
  }, []);

  const setBusReserve = useCallback(async (busReserve) => {
    localStorage.setItem(OnePlaceBusReserveData, JSON.stringify(busReserve));
    setBusReserveData(busReserve);
  }, []);

  const setParkingReserve = useCallback(async (parkingReserve) => {
    localStorage.setItem(
      OnePlaceParkingReserveData,
      JSON.stringify(parkingReserve)
    );
    setParkingReserveData(parkingReserve);
  }, []);

  const [timeOptionsData, setTimeOptionsData] = useState(hourArray);

  const setTimeOptions = useCallback(async (data: string[]) => {
    setTimeOptionsData(data);
  }, []);

  const [timeOptions2Data, setTimeOptions2Data] = useState(hourArray.slice(1));

  const setTimeOptions2 = useCallback(async (data: string[]) => {
    setTimeOptions2Data(data);
  }, []);

  const [defaultOptionData, setDefaultOptionData] = useState(
    timeOptionsData[0]
  );
  const setDefaultOption = useCallback(async (data: string) => {
    setDefaultOptionData(data);
  }, []);

  const [defaultOption2Data, setDefaultOption2Data] = useState(
    timeOptions2Data[0]
  );
  const setDefaultOption2 = useCallback(async (data: string) => {
    setDefaultOption2Data(data);
  }, []);

  const [workShiftData, setWorkShiftData] = useState('');
  const setWorkShift = useCallback(async (data: string) => {
    setWorkShiftData(data);
  }, []);

  const [busRealTimeData, setBusRealTimeData] = useState<BusReserver>();
  const setBusRealTime = useCallback(async (data: BusReserver) => {
    setBusRealTimeData(data);
  }, []);

  const [menuPersonalData, setMenuPersonalData] = useState<string>('');

  const setMenuPersonal = useCallback(async (data: string) => {
    setMenuPersonalData(data);
  }, []);

  const [listStatusCoparticipationData, setListStatusCoparticipationData] =
    useState<ICoparticipationSolicitation>();

  const setListStatusCoparticipation = useCallback(
    async (data: ICoparticipationSolicitation | undefined) => {
      setListStatusCoparticipationData(data);
    },
    []
  );

  const newReserv = useCallback(() => {
    localStorage.removeItem('@OnePlace: unityID');
    localStorage.removeItem(OnePlaceBuildingID);
    localStorage.removeItem(OnePlaceRoomID);
    localStorage.removeItem(OnePlaceTableID);
    localStorage.removeItem('@OnePlace: dateDate');
    localStorage.removeItem(OnePlaceMealData);
    localStorage.removeItem(OnePlaceFloorID);
    localStorage.removeItem(OnePlaceBusReserveData);
    setRoomData({} as RoomState);
    setUnityData({} as UnitySelect);
    setTableData({} as TableState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: userData,
        name: userData.name,
        userIDS: userData,
        setUserIDS: setUserData,
        photo: photoData,
        login: userData.login,
        email: '',
        unity: unityData,
        building: buldingData.id,
        room: roomData.id,
        table: tableData.id,
        meal: mealData.meal,
        startHour: startHourData,
        endHour: endHourData,
        reserve: reserveData.id,
        floor: floorData.id,
        isAuth: auth.isAuth,
        messagingPush: userMessagingPush.session,
        busReserve: busReserveData,
        isDesktop: isDesktopData,
        placing: placingData.placing,
        transportIsGuest,
        setTransportIsGuest,
        checkUser,
        checkMeal,
        checkStartHour,
        checkEndHour,
        checkUnity,
        checkBuilding,
        checkFloor,
        checkRoom,
        checkTable,
        checkReserve,
        checkAuth,
        newReserv,
        checkPlacing,
        checkMessagingPush,
        setPhoto,
        setBusReserve,
        timeOptions: timeOptionsData,
        setTimeOptions,
        timeOptions2: timeOptions2Data,
        setTimeOptions2,
        defaultOption: defaultOptionData,
        setDefaultOption,
        defaultOption2: defaultOption2Data,
        setDefaultOption2,
        checkUserId,
        userId: userIdData,
        checkName,
        userName: nameData,
        checkUnityRestaurant,
        unityRestaurant: unityRestaurantData,
        checkUnityWorkstation,
        unityWorkstation: unityWorkstationData,
        checkUnityTransport,
        unityTransport: unityTransportData,
        setFixWorkstation,
        fixWorkstation: fixWorkstationData,
        checkRestaurantReserv,
        restaurantReserv: reserveRestaurantData,
        setGroupsApi,
        groupsApi: groupsApiData,
        setWorkShift,
        workShift: workShiftData,
        unitys: unitysData,
        checkUnitys,
        reservedBus: reservedBusData,
        setReservedBus,
        parkingReserve: parkingReserveData,
        setParkingReserve,
        codeCaju: codeCajuData,
        setCodeCaju,
        busRealTime: busRealTimeData,
        setBusRealTime,
        menuPersonal: menuPersonalData,
        setMenuPersonal,
        listStatusCoparticipation: listStatusCoparticipationData,
        setListStatusCoparticipation,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ReservesProvider>
          <RestaurantProvider>
            <TransportProvider>
              <BusControllerProvider>
                <AdministratorProvider>{children}</AdministratorProvider>
              </BusControllerProvider>
            </TransportProvider>
          </RestaurantProvider>
        </ReservesProvider>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  return useContext(AuthContext);
}
