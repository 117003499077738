import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { BsGraphUp } from 'react-icons/bs';
import IconLogout from '../../../assets/icons/iconlogout.svg';
import IconCar from '../../../assets/icons/iconcar.svg';
import { useAuth } from '../../../context/AuthContext';
import reservVisit from '../../../assets/icons/reserv-visit.png';
import SAPFIORI from '../../../assets/SAPFIORI.svg';
import { RightBarWhite, Invisible } from './styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function LocalizationReserves(location: any): string | undefined {
  return `right-bar-white ${location.pathname === '/Reservar' ? 'active' : ''}`;
}

interface DrawerComponentProps {
  cancel: () => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function Drawer({ cancel }: DrawerComponentProps) {
  const { isDesktop, userIDS } = useAuth();
  const router = useHistory();
  const location = useLocation();
  const cookies = new Cookies();

  return (
    <>
      <Invisible
        onClick={() => {
          cancel();
        }}
      />
      {!isDesktop && (
        <RightBarWhite>
          <button
            className={`right-bar-white ${
              location.pathname === '/Parking' ? 'active' : ''
            }`}
            type="button"
            onClick={() => {
              router.push('/ParkingGuest');
              cancel();
            }}
          >
            <img src={IconCar} alt="Car" style={{ width: '12%' }} />
            <div className="right-bar-white-txt">Reserva de estacionamento</div>
          </button>
          <button
            className={LocalizationReserves(location)}
            type="button"
            onClick={() => {
              router.push('/Reservavisitantes');
              cancel();
            }}
          >
            <img src={reservVisit} alt="Car" style={{ width: '12%' }} />
            <div className="right-bar-white-txt">Reserva para visitantes</div>
          </button>

          {userIDS.lead && (
            <button
              className={LocalizationReserves(location)}
              type="button"
              onClick={() => {
                window.open('https://sapfiori.embraer.com.br/', '_blank');
                cancel();
              }}
            >
              <img src={SAPFIORI} alt="SAP Fiori" style={{ width: '12%' }} />
              <div className="right-bar-white-txt">SAP Fiori</div>
            </button>
          )}

          <button
            className={LocalizationReserves(location)}
            type="button"
            onClick={() => {
              router.push('/Dashboard');
              cancel();
            }}
          >
            <BsGraphUp size={24} />
            <div className="right-bar-white-txt">Dashboard</div>
          </button>

          {userIDS.lead && (
            <button
              className={LocalizationReserves(location)}
              type="button"
              onClick={() => {
                window.open('https://sapfiori.embraer.com.br/', '_blank');
                cancel();
              }}
            >
              <img src={SAPFIORI} alt="Dashboard" style={{ width: '12%' }} />
              <div className="right-bar-white-txt">SAP Fiori</div>
            </button>
          )}

          <button
            className="right-bar-white end-page"
            type="button"
            onClick={() => {
              cookies.remove('@OnePlace: AuthToken');
              cancel();
              window.location.href =
                'https://idp.embraer.com.br/vdesk/hangup.php3';
            }}
          >
            <img src={IconLogout} alt="Logout" />
            <div className="right-bar-white-txt">Sair</div>
          </button>
        </RightBarWhite>
      )}
    </>
  );
}

export default Drawer;
