import { shade } from 'polished';
import styled from 'styled-components';
import vw from '../../../utils/vw';

interface Resolutions {
  isDesktop?: boolean;
}

export const ContainerPersonal = styled.div`
  width: 100%;
  color: #ffffff;
  background: #100690;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
  padding-bottom: '5px';
  display: grid;
  grid-template-columns: 40px 2fr 1fr;

  height: 60px;
  z-index: 999;
  position: fixed;
  align-items: center;

  .EmbLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }

  .circle {
    height: 50px;
    position: relative;
    overflow: hidden;
    left: 0%;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Invisible = styled.div`
  background: black;
  opacity: 0.4;

  width: 100%;
  top: 23px;
  z-index: 999;
  left: 0;

  position: fixed;
  margin-top: 40px;
  height: calc(100vh - 130px);
`;

export const RightBarWhite = styled.div`
  background: #ffffff;

  width: 60%;
  top: 23px;
  z-index: 999;
  right: 0;

  position: fixed;
  margin-top: 40px;
  height: calc(100vh - 130px);

  animation: fromTop 0.5s 0.2s backwards;

  @keyframes fromTop {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .right-bar-white {
    align-items: center;
    justify-content: left;
    padding: 8px;
    display: flex;
    height: 50px;
    width: 100%;

    color: #5c6c74;
  }

  .active {
    border-right: 4px solid #000;
  }

  .right-bar-white-txt {
    color: transparent;
    width: 0;
    height: 0;
  }

  .end-page {
    height: 20%;
    display: flex;
    align-items: flex-end;
  }

  .active {
    color: #000 !important;
    img {
      filter: brightness(0);
    }
  }

  .right-bar-white-txt {
    width: auto;
    height: auto;
    color: #5c6c74;
    margin-left: 10px;
  }

  .active {
    color: #000 !important;
  }
`;
