import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ButtomSides from '../components/global/BottomSides';
import { AdministratorUserControlProvider } from '../context/AdministratorUserControl';
import { useAuth } from '../context/AuthContext';
import GlobalStyle from '../styles/global';

const HomePage = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/HomePage')
);

const ParkingGuestPage = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/ParkingGuest')
);

const Administrator = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/Administrator')
);

const FazerReserva = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/FazerReserva')
);
const ReservaVisitantes = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/ReservaVisitantes')
);
const BuscarPredio = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/BuscarPredio')
);
const MinhasReservas = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/MinhasReservas')
);
const ChecarReserva = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/ChecarReserva')
);
const CheckBusReserve = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/CheckBusReserve')
);
const CheckParking = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/CheckParking')
);
const CheckRestaurantReserve = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/ChecarRestaurantReserve')
);
const BuscarUnidade = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/BuscarUnidade')
);
const BuscarSalas = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/BuscarSalas')
);
const SelectFloorNTable = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/SelectFloorNTable')
);
const ReservaConcluida = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/ReservaConcluida')
);
const Checkin = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/Checkin')
);
const CheckinCompleted = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/CheckinCompleted')
);
const Login = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/Login')
);
const RedirectUrl = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/RedirectUrl')
);
const SelectTransport = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/SelectTransport')
);
const BuscarRestaurante = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/BuscarRestaurante')
);
const Feedback = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/Feedback')
);
const FirstAccess = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/FirstAccess')
);
const PersonalInfo = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/PersonalInfo')
);
const QrCodeDownload = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/DownloadQrCode')
);

const ListReportsPage = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/ListReports')
);

const InternaryPage = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/Internary')
);

const CargoPage = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/CargoReports')
);

const RealTimePage = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/RealTime')
);

const DashboardPage = React.lazy(
  () => import(/* webpackPrefetch: true */ '../pages/Dashboard')
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function PrivateRoute({ children, ...rest }: any): JSX.Element {
  const cookies = new Cookies();
  const isAuth = cookies.get('@OnePlace: AuthToken');
  const isFirstAccess = useAuth();
  return (
    <Route
      {...rest}
      render={() => {
        return isAuth ? (
          <>
            {isFirstAccess.userIDS.firstAccess && (
              <>
                <Route component={FirstAccess} />
                <GlobalStyle url={window.location.pathname} />
              </>
            )}
            {!isFirstAccess.userIDS.firstAccess && (
              <>
                {children}
                <GlobalStyle url={window.location.pathname} />
              </>
            )}
          </>
        ) : (
          <Route component={Login} />
        );
      }}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function AdminRoute({ children, ...rest }: any): JSX.Element {
  const { groupsApi } = useAuth();
  return (
    <Route
      {...rest}
      render={() => {
        return groupsApi ? (
          <>
            {children}
            <GlobalStyle url={window.location.pathname} />
          </>
        ) : (
          <Route component={Login} />
        );
      }}
    />
  );
}

const Routes: React.FC = () => (
  <BrowserRouter>
    <Suspense
      fallback={
        <div style={{ color: '#000', textAlign: 'center' }}>Carregando...</div>
      }
    >
      <Switch>
        <PrivateRoute path="/Home">
          <Route exact component={HomePage} />
        </PrivateRoute>
        <PrivateRoute path="/Reservar">
          <Route exact component={FazerReserva} />
        </PrivateRoute>
        <PrivateRoute path="/Itinerary">
          <Route component={InternaryPage} />
        </PrivateRoute>
        <PrivateRoute path="/ReservaVisitantes">
          <Route component={ReservaVisitantes} />
        </PrivateRoute>
        <PrivateRoute path="/BuscarPredio">
          <Route component={BuscarPredio} />
        </PrivateRoute>
        <PrivateRoute path="/MinhasReservas">
          <Route component={MinhasReservas} />
        </PrivateRoute>
        <PrivateRoute path="/ChecarReserva">
          <Route component={ChecarReserva} />
        </PrivateRoute>
        <PrivateRoute path="/CheckBusReserve">
          <Route component={CheckBusReserve} />
        </PrivateRoute>
        <PrivateRoute path="/CheckRestaurant">
          <Route component={CheckRestaurantReserve} />
        </PrivateRoute>
        <PrivateRoute path="/CheckParking">
          <Route component={CheckParking} />
        </PrivateRoute>
        <PrivateRoute path="/BuscarUnidade">
          <Route component={BuscarUnidade} />
        </PrivateRoute>
        <PrivateRoute path="/SelectTransport">
          <Route component={SelectTransport} />
        </PrivateRoute>
        <PrivateRoute path="/BuscarSalas">
          <Route component={BuscarSalas} />
        </PrivateRoute>
        <PrivateRoute path="/BuscarRestaurante">
          <Route component={BuscarRestaurante} />
        </PrivateRoute>
        <PrivateRoute path="/SelecionarAndarEMesa">
          <Route component={SelectFloorNTable} />
        </PrivateRoute>
        <PrivateRoute path="/ReservaConcluida">
          <Route component={ReservaConcluida} />
        </PrivateRoute>
        <PrivateRoute path="/Checkin">
          <Route component={Checkin} />
        </PrivateRoute>
        <PrivateRoute path="/ParkingGuest">
          <Route component={ParkingGuestPage} />
        </PrivateRoute>
        <PrivateRoute path="/Checkin">
          <Route component={CheckinCompleted} />
        </PrivateRoute>
        <PrivateRoute path="/Feedback">
          <Route component={Feedback} />
        </PrivateRoute>
        <PrivateRoute path="/FirstAccess">
          <Route component={FirstAccess} />
        </PrivateRoute>
        <PrivateRoute path="/PersonalInfo">
          <Route component={PersonalInfo} />
        </PrivateRoute>
        <PrivateRoute path="/RealTime">
          <Route component={RealTimePage} />
        </PrivateRoute>
        <PrivateRoute path="/Dashboard">
          <Route component={DashboardPage} />
        </PrivateRoute>
        <AdminRoute path="/Administrator" exact>
          <Route component={Administrator} />
        </AdminRoute>
        <AdminRoute path="/Administrator/QrCode">
          <AdministratorUserControlProvider>
            <Route component={QrCodeDownload} />
          </AdministratorUserControlProvider>
        </AdminRoute>
        <AdminRoute path="/Administrator/Reports">
          <Route component={ListReportsPage} />
        </AdminRoute>
        <AdminRoute path="/Administrator/cargo">
          <Route component={CargoPage} />
        </AdminRoute>
        <PrivateRoute path="/" exact>
          <Route component={RedirectUrl} />
        </PrivateRoute>
        <Route path="*" component={RedirectUrl} />
      </Switch>
      <Route path="/:id" component={ButtomSides} />
    </Suspense>
  </BrowserRouter>
);

export default Routes;
