import { createContext, useCallback, useContext, useState } from 'react';
import { BusControllerContextInterface } from '../interface/context/BusControllerContext';
import { UnitySelect } from '../interface/global/AuthInterface';
import {
  BusLine,
  LineRouting,
  SelectOptions,
} from '../interface/global/BusLineInterface';

export const BusControllerContext =
  createContext<BusControllerContextInterface>(
    {} as BusControllerContextInterface
  );

export const BusControllerProvider: React.FC = ({ children }) => {
  const [unityData, setUnityData] = useState<UnitySelect>();

  const setUnity = useCallback(async (data: UnitySelect) => {
    setUnityData(data);
  }, []);

  const [busLineData, setBusLineData] = useState<BusLine[]>([]);

  const setBusLine = useCallback(async (data: BusLine[]) => {
    setBusLineData(data);
  }, []);

  const [busLineEditData, setBusLineEditData] = useState<BusLine>();

  const setBusLineEdit = useCallback(async (data: BusLine) => {
    setBusLineEditData(data);
  }, []);

  const [busLineEditRoutingData, setBusLineEditRoutingData] = useState<
    LineRouting[]
  >([]);

  const setBusLineEditRouting = useCallback(async (data: LineRouting[]) => {
    setBusLineEditRoutingData(data);
  }, []);

  const [createData, setCreateData] = useState<boolean>(false);

  const setCreate = useCallback(async (data: boolean) => {
    setCreateData(data);
  }, []);

  const [cityData, setCityData] = useState<SelectOptions>();

  const setCity = useCallback(async (data: SelectOptions | undefined) => {
    setCityData(data);
  }, []);

  const [neighborhoodData, setNeighborhoodData] = useState<SelectOptions>();

  const setNeighborhood = useCallback(
    async (data: SelectOptions | undefined) => {
      setNeighborhoodData(data);
    },
    []
  );

  const [turnData, setTurnData] = useState<SelectOptions>();

  const setTurn = useCallback(async (data: SelectOptions | undefined) => {
    setTurnData(data);
  }, []);

  const [busMethodData, setBusMethodData] = useState<string>('1');

  const setBusMethod = useCallback(async (data: string) => {
    setBusMethodData(data);
  }, []);

  const [linkMapData, setLinkMapData] = useState<string | undefined>();

  const setLinkMap = useCallback(async (data: string | undefined) => {
    setLinkMapData(data);
  }, []);

  const [busEmbarkData, setBusEmbarkData] = useState<BusLine>();

  const setBusEmbark = useCallback(async (data?: BusLine) => {
    setBusEmbarkData(data);
  }, []);

  const [busDisembarkData, setBusDisembarkData] = useState<BusLine>();

  const setBusDisembark = useCallback(async (data?: BusLine) => {
    setBusDisembarkData(data);
  }, []);

  const cleanData = useCallback(async () => {
    setUnityData(undefined);
    setBusLineData([]);
    setBusLineEditData(undefined);
    setBusLineEditRoutingData([]);
    setBusDisembark(undefined);
    setBusEmbark(undefined);
    setCreateData(false);
    setCityData(undefined);
    setNeighborhoodData(undefined);
    setTurnData(undefined);
    setLinkMapData(undefined);
  }, []);

  return (
    <BusControllerContext.Provider
      value={{
        setUnity,
        unity: unityData,
        setBusLine,
        busLine: busLineData,
        setBusLineEdit,
        busLineEdit: busLineEditData,
        setBusLineEditRouting,
        busLineEditRouting: busLineEditRoutingData,
        setCity,
        city: cityData,
        setNeighborhood,
        neighborhood: neighborhoodData,
        setTurn,
        turn: turnData,
        create: createData,
        setBusMethod,
        busMethod: busMethodData,
        setCreate,
        linkMap: linkMapData,
        setLinkMap,
        setBusEmbark,
        busEmbark: busEmbarkData,
        setBusDisembark,
        busDisembark: busDisembarkData,
        cleanData,
      }}
    >
      {children}
    </BusControllerContext.Provider>
  );
};

export function useBusController(): BusControllerContextInterface {
  return useContext(BusControllerContext);
}
