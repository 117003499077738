import axios from 'axios';
import Cookies from 'universal-cookie';

import dayjs from 'dayjs';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

interface IUserInterface {
  exp: number;
}

const cookies = new Cookies();

const constOnePlaceAuthToken = '@OnePlace: AuthToken';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let authTokens = cookies.get(constOnePlaceAuthToken)
  ? cookies.get(constOnePlaceAuthToken)
  : '';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

// eslint-disable-next-line consistent-return
api.interceptors.request.use(async (req) => {
  const requestModify = req;
  const requestDefault = req;
  authTokens = cookies.get(constOnePlaceAuthToken);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  requestModify.headers!.Authorization = `Bearer ${authTokens}`;

  if (authTokens) {
    const user: IUserInterface = jwt_decode(authTokens);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    if (!isExpired) return requestDefault;
    const refreshToken = cookies.get('@OnePlace: RefreshToken');
    const response = await axios
      .post(`${api.defaults.baseURL}auth/refreshToken`, {
        refresh_token: refreshToken,
      })
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        cookies.remove(constOnePlaceAuthToken);
        cookies.remove('@OnePlace: RefreshToken');
        window.alert('Sua sessão expirou, por favor faça login novamente');
        window.location.href = '/';
      });

    cookies.set(constOnePlaceAuthToken, response.token.token);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    requestModify.headers!.Authorization = `Bearer ${response.token.token}`;
  }
  return requestModify;
});

export default api;
