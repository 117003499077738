import moment from 'moment';
import { createContext, useCallback, useContext, useState } from 'react';
import { RestaurantContextInterface } from '../interface/context/RestaurantContext';

export const RestaurantContext = createContext<RestaurantContextInterface>(
  {} as RestaurantContextInterface
);

export const RestaurantProvider: React.FC = ({ children }) => {
  const [restaurantData, setRestaurantData] = useState<boolean>(false);

  const setRestaurant = useCallback(async (data: boolean) => {
    setRestaurantData(data);
  }, []);

  const [startHourData, setStartHourData] = useState<Date>(new Date());

  const setStartHour = useCallback(
    async (time) => {
      let date = new Date(startHourData);
      if (time.hour) {
        date = moment(date)
          .set({
            hour: time.hour.split(':')[0],
            minute: time.hour.split(':')[1],
            second: 0,
            milliseconds: 0,
          })
          .toDate();
      }
      if (time.date) {
        date.setDate(time.date.getDate());
        date.setMonth(time.date.getMonth());
        date.setFullYear(time.date.getFullYear());
      }
      setStartHourData(date);
    },
    [startHourData]
  );

  const [endHourData, setEndHourData] = useState<Date>(new Date());

  const setEndHour = useCallback(
    async (time) => {
      let date = new Date(endHourData);
      if (time.hour) {
        date = moment(date)
          .set({
            hour: time.hour.split(':')[0],
            minute: time.hour.split(':')[1],
            second: 0,
            milliseconds: 0,
          })
          .toDate();
      }
      if (time.date) {
        date.setDate(time.date.getDate());
        date.setMonth(time.date.getMonth());
        date.setFullYear(time.date.getFullYear());
      }
      setEndHourData(date);
    },
    [endHourData]
  );

  const [timeOptionsData, setTimeOptionsData] = useState<string[]>([]);

  const setTimeOptions = useCallback(async (data: string[]) => {
    setTimeOptionsData(data);
  }, []);

  const [timeOptionsData2, setTimeOptionsData2] = useState<string[]>([]);

  const setTimeOptions2 = useCallback(async (data: string[]) => {
    setTimeOptionsData2(data);
  }, []);

  const [defaultOptionData, setDefaultOptionData] = useState<string>('');

  const setDefaultOption = useCallback(async (data: string) => {
    setDefaultOptionData(data);
  }, []);

  const [defaultOption2Data, setDefaultOption2Data] = useState<string>('');

  const setDefaultOption2 = useCallback(async (data: string) => {
    setDefaultOption2Data(data);
  }, []);

  const [guestData, setGuestData] = useState<boolean>(false);

  const setGuest = useCallback(async (data: boolean) => {
    setGuestData(data);
  }, []);

  const [guestQuantityData, setGuestQuantityData] = useState<number>(1);
  const setGuestQuantity = useCallback(async (data: number) => {
    setGuestQuantityData(data);
  }, []);

  const [companyData, setCompanyData] = useState<string>('');
  const setCompany = useCallback(async (data: string) => {
    setCompanyData(data);
  }, []);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <RestaurantContext.Provider
      value={{
        setStartHour,
        startHour: startHourData,
        setEndHour,
        endHour: endHourData,
        setRestaurant,
        restaurant: restaurantData,
        setTimeOptions,
        timeOptions: timeOptionsData,
        setTimeOptions2,
        timeOptions2: timeOptionsData2,
        setDefaultOption,
        defaultOption: defaultOptionData,
        setDefaultOption2,
        defaultOption2: defaultOption2Data,
        setGuest,
        guest: guestData,
        setGuestQuantity,
        guestQuantity: guestQuantityData,
        setCompany,
        company: companyData,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  );
};

export function useRestaurant(): RestaurantContextInterface {
  return useContext(RestaurantContext);
}
