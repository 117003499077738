import { createGlobalStyle } from 'styled-components';
import fundo from '../assets/exec.jpg';
import { FONT_FAMILY } from '../components_styles/fonts';

interface IUrlInterface {
  url: string;
}

const img = new Image();
img.src = fundo;

function funcProps() {
  return (props) => {
    if (
      props.url === '/administrator' ||
      props.url === '/administrator/cargo' ||
      props.url === '/administrator/reports'
    ) {
      return `
        background: rgb(2,0,36);
        background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(5,2,59,1) 9%, rgba(8,3,81,1) 18%, rgba(11,4,106,1) 28%, rgba(14,5,129,1) 37%, rgba(16,6,144,1) 43%, rgba(16,6,144,1) 55%, rgba(16,6,144,1) 63%, rgba(16,6,144,1) 72%, rgba(16,6,144,1) 78%, rgba(16,6,144,1) 85%, rgba(16,6,144,1) 93%);
      `;
    }
    return `
        background-image: linear-gradient(#1006904b, #1006904b), url(${fundo});
      `;
  };
}

export default createGlobalStyle<IUrlInterface>`


  * {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  }

  html {
    min-height: 100%;
}

  body {
    touch-action: pan-y;
    background: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    font-family: ${FONT_FAMILY};
    -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    ${funcProps()}
    -webkit-font-smoothing: antialiased;

  }

  body, input, button {
    font: 16px ${FONT_FAMILY};

  .MuiAutocomplete-popper{
    z-index: 2001;
  }

  .react-transform-wrapper{
    height: auto !important;
  }

    @media (max-width: 768px) {
      font: 12px  ${FONT_FAMILY};
    }
  }

  button{
    cursor: pointer;
  }

  .PrimaryColor {
    background: #100690;
  }

  .SecondaryColor {
    background: #005AAF;
  }

  .overflow-x-scroll::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
    height: 6px;
  }

  .overflow-x-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }

  .scrollColor {
    &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #c6c6c6;
    margin-bottom: 2px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4841a0;
    border-radius: 20px;
  }
  }

  @layer utilities {
  .overflow-wrap-anywhere {
    overflow-wrap: anywhere;
  }

  .border-collapse {
  border-collapse: collapse;
   }
}

`;
