import React, { createContext, useCallback, useContext, useState } from 'react';
import { TransportBusContextInterface } from '../interface/context/TransportBusContextInterface';
import {
  findLineByStopsInterface,
  LineBus,
  LineState,
  StopState,
} from '../interface/global/TransportBusInterface';
import { WorkShiftTypes } from '../interface/global/WorkShiftTransprot';

export const TransportBusContext = createContext<TransportBusContextInterface>(
  {} as TransportBusContextInterface
);

const constOnePlaceStopGoingData = '@OnePlace: stopGoingData';
const OnePlaceStopReturningData = '@OnePlace: stopReturningData';
const OnePlaceLineListGoingData = '@OnePlace: lineListGoingData';
const OnePlaceLineListReturningData = '@OnePlace: lineListReturningData';

export const TransportBusProvider: React.FC = ({ children }) => {
  const [selectionGoingData, setSelectionGoingBusData] = useState<string>(
    () => {
      const data = localStorage.getItem('@OnePlace: SelectionGoing');

      if (data) {
        return JSON.parse(data);
      }

      return '' as string;
    }
  );

  const setSelectionGoing = useCallback(async (selectionGoing) => {
    localStorage.setItem(
      '@OnePlace: SelectionGoing',
      JSON.stringify(selectionGoing)
    );

    setSelectionGoingBusData(selectionGoing);
  }, []);

  const [stopGoingData, setStopGoingData] = useState<StopState>(() => {
    const data = localStorage.getItem(constOnePlaceStopGoingData);

    if (data) {
      return JSON.parse(data);
    }

    return {
      distance: '',
      id: '',
      latitude: '',
      longitude: '',
      name: '',
    } as unknown as StopState;
  });

  const setStopGoing = useCallback(async (data: StopState) => {
    localStorage.setItem(constOnePlaceStopGoingData, JSON.stringify(data));
    setStopGoingData(data);
  }, []);

  const [stopReturningData, setStopReturningData] = useState<StopState>(() => {
    const data = localStorage.getItem(OnePlaceStopReturningData);

    if (data) {
      return JSON.parse(data);
    }

    return {
      distance: '',
      id: '',
      latitude: '',
      longitude: '',
      name: '',
    } as unknown as StopState;
  });

  const setStopReturning = useCallback(async (data: StopState) => {
    localStorage.setItem(OnePlaceStopReturningData, JSON.stringify(data));

    setStopReturningData(data);
  }, []);

  const [lineListGoingData, setLineListGoingData] = useState<LineState[]>(
    () => {
      const data = localStorage.getItem(OnePlaceLineListGoingData);

      if (data) {
        return JSON.parse(data);
      }
      const empty: LineState[] = [];
      return empty;
    }
  );

  const setLineListGoing = useCallback(async (data: LineState[]) => {
    localStorage.setItem(OnePlaceLineListGoingData, JSON.stringify(data));
    setLineListGoingData(data);
  }, []);

  const [lineListReturningData, setLineListReturningData] = useState<
    LineState[]
  >(() => {
    const data = localStorage.getItem(OnePlaceLineListReturningData);

    if (data) {
      return JSON.parse(data);
    }
    const empty: LineState[] = [];
    return empty;
  });

  const setLineListReturning = useCallback(async (data: LineState[]) => {
    localStorage.setItem(OnePlaceLineListReturningData, JSON.stringify(data));

    setLineListReturningData(data);
  }, []);

  const [stopListGoingData, setStopListGoingData] = useState<StopState[]>(
    () => {
      const data = localStorage.getItem('@OnePlace: stopListGoingData');

      if (data) {
        return JSON.parse(data);
      }
      const empty: StopState[] = [];
      return empty;
    }
  );

  const setStopListGoing = useCallback(async (data: StopState[]) => {
    localStorage.setItem('@OnePlace: stopListData', JSON.stringify(data));

    setStopListGoingData(data);
  }, []);

  const [stopListValeGoingData, setStopListValeGoingData] =
    useState<findLineByStopsInterface[]>();

  const setStopListValeGoing = useCallback(
    async (data: findLineByStopsInterface[]) => {
      setStopListValeGoingData(data);
    },
    []
  );

  const [stopListValeReturningData, setStopListValeReturningData] =
    useState<findLineByStopsInterface[]>();

  const setStopListValeReturning = useCallback(
    async (data: findLineByStopsInterface[]) => {
      setStopListValeReturningData(data);
    },
    []
  );

  const [previewLineData, setPreviewLineData] =
    useState<findLineByStopsInterface>();

  const setPreviewLine = useCallback(async (data: findLineByStopsInterface) => {
    setPreviewLineData(data);
  }, []);

  const [stopListReturningData, setStopListReturningData] = useState<
    StopState[]
  >(() => {
    const data = localStorage.getItem('@OnePlace: stopListReturningData');

    if (data) {
      return JSON.parse(data);
    }
    const empty: StopState[] = [];
    return empty;
  });

  const setStopListReturning = useCallback(async (data: StopState[]) => {
    localStorage.setItem('@OnePlace: stopListData', JSON.stringify(data));

    setStopListReturningData(data);
  }, []);

  const [lineGoingBusData, setLineGoingBusData] = useState<LineBus>(() => {
    const point = localStorage.getItem('@OnePlace: lineGoingBus');

    if (point) {
      return JSON.parse(point);
    }

    return { point: '' } as LineBus;
  });

  const setLineGoingBus = useCallback(async (point) => {
    localStorage.setItem('@OnePlace: busGoing', JSON.stringify(point));

    setLineGoingBusData({ point });
  }, []);

  const [lineReturningBusData, setLineReturningBusData] = useState<LineBus>(
    () => {
      const point = localStorage.getItem('@OnePlace: lineReturningBus');

      if (point) {
        return JSON.parse(point);
      }

      return { point: '' } as LineBus;
    }
  );

  const setLineReturningBus = useCallback(async (point) => {
    localStorage.setItem('@OnePlace: busReturning', JSON.stringify(point));

    setLineReturningBusData({ point });
  }, []);

  const newTransportBus = useCallback(() => {
    localStorage.removeItem(OnePlaceLineListGoingData);
    setLineListGoingData([]);
    localStorage.removeItem(constOnePlaceStopGoingData);
    setStopGoingData({
      distance: 0,
      id: '',
      latitude: 0,
      longitude: 0,
      name: '',
    });
    setStopReturningData({
      distance: 0,
      id: '',
      latitude: 0,
      longitude: 0,
      name: '',
    });
    localStorage.removeItem(OnePlaceStopReturningData);
    localStorage.removeItem('@OnePlace: stopListGoingData');
    localStorage.removeItem('@OnePlace: stopListReturningData');
    localStorage.removeItem(OnePlaceLineListReturningData);
    localStorage.removeItem('@OnePlace: returningStopData');
    localStorage.removeItem('@OnePlace: returningLineData');
    localStorage.removeItem('@OnePlace: busGoing');
    localStorage.removeItem('@OnePlace: busReturning');
  }, []);

  const lineListGoingRemove = useCallback(() => {
    localStorage.removeItem(OnePlaceLineListGoingData);
    setLineListGoingData([]);
  }, []);

  const lineListReturningRemove = useCallback(() => {
    localStorage.removeItem(OnePlaceLineListReturningData);
  }, []);

  const [stepValeData, setStepValeData] = useState<number>(0);

  const setStepVale = useCallback(async (data: number) => {
    setStepValeData(data);
  }, []);

  const [lineGoingData, setLineGoingData] = useState('');
  const setLineGoing = useCallback(async (data: string) => {
    setLineGoingData(data);
  }, []);

  const [lineReturningData, setLineReturningData] = useState('');
  const setLineReturning = useCallback(async (data: string) => {
    setLineReturningData(data);
  }, []);

  const [workShiftData, setWorkShiftData] = useState<WorkShiftTypes>();

  const setWorkShift = useCallback(async (data: WorkShiftTypes) => {
    setWorkShiftData(data);
  }, []);

  return (
    <TransportBusContext.Provider
      value={{
        stepVale: stepValeData,
        setStepVale,
        setSelectionGoing,
        selectionGoing: selectionGoingData,
        setStopGoing,
        stopGoing: stopGoingData,
        setStopReturning,
        stopReturning: stopReturningData,
        setStopListGoing,
        stopListGoing: stopListGoingData,
        setStopListValeGoing,
        stopListValeGoing: stopListValeGoingData,
        setStopListValeReturning,
        stopListValeReturning: stopListValeReturningData,
        setLineListGoing,
        lineListGoing: lineListGoingData,
        setStopListReturning,
        stopListReturning: stopListReturningData,
        setLineListReturning,
        lineListReturning: lineListReturningData,
        setLineGoingBus,
        lineGoingBus: lineGoingBusData.point,
        setLineReturningBus,
        lineReturningBus: lineReturningBusData.point,
        setPreviewLine,
        previewLine: previewLineData,
        lineListGoingRemove,
        lineListReturningRemove,
        lineGoing: lineGoingData,
        setLineGoing,
        lineReturning: lineReturningData,
        setLineReturning,
        workShift: workShiftData,
        setWorkShift,
        newTransportBus,
      }}
    >
      {children}
    </TransportBusContext.Provider>
  );
};

export function useTransportBus(): TransportBusContextInterface {
  return useContext(TransportBusContext);
}
