import { createContext, useCallback, useContext, useState } from 'react';
import { IPermissionAndType } from '../components/CheckPermission/interface';
import { AdministratorContextInterface } from '../interface/context/AdministratorContext';
import { LeftBarAdministrator } from '../interface/global/AdministratorInterface';
import { AdministratorUserControlProvider } from './AdministratorUserControl';

export const AdministratorContext =
  createContext<AdministratorContextInterface>(
    {} as AdministratorContextInterface
  );

export const AdministratorProvider: React.FC = ({ children }) => {
  const [leftbarData, setleftbarData] = useState<LeftBarAdministrator>(() => {
    const data = localStorage.getItem('@OnePlace: leftbarData');

    if (data) {
      return JSON.parse(data);
    }

    return {
      hidden: false,
    } as unknown as LeftBarAdministrator;
  });

  const setLeftBar = useCallback(async (data: LeftBarAdministrator) => {
    localStorage.setItem('@OnePlace: LeftbarData', JSON.stringify(data));
    setleftbarData(data);
  }, []);

  const [permissionsData, setPermissionsData] = useState<IPermissionAndType[]>(
    []
  );

  const setPermissions = useCallback(async (data: IPermissionAndType[]) => {
    setPermissionsData(data);
  }, []);

  const [homeData, setHomeData] = useState<string>('');

  const setHome = useCallback(async (data: string) => {
    setHomeData(data);
  }, []);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <AdministratorContext.Provider
      value={{
        setLeftBar,
        leftBar: leftbarData.hidden,
        setPermissions,
        permissions: permissionsData,
        setHome,
        home: homeData,
      }}
    >
      <AdministratorUserControlProvider>
        {children}
      </AdministratorUserControlProvider>
    </AdministratorContext.Provider>
  );
};

export function useAdministrator(): AdministratorContextInterface {
  return useContext(AdministratorContext);
}
