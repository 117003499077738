import styled from 'styled-components';
// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  top: 92vh;
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 10.4vh;
  background: #fff;
  padding: 4px 10px 5px 10px;
  @media (max-height: 768px) {
    top: 90%;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: flex-start;

  }

  .Mui-selected {
    color: #100690;
  }

  .MuiBottomNavigationAction-root{
    width: '50px',
    height: '50px',
    color: '#5C6C74',
    '&$selected': {
      color: '#172B4D',
    }
  }

  .css-imwso6-MuiBottomNavigationAction-label.Mui-selected {
    font-size: 12px;
}

  .css-1gnz8kn-MuiBottomNavigation-root {
    height: auto !important;
    align-items: flex-start;
    justify-content: space-around;
    padding: 0 0 10px 0;
  }

  .MuiBottomNavigationAction-label {
    padding-top: 5px;
  }

  .reserve{
    width: '50px',
    height: '50px',
    color: '#5C6C74',
    '&$selected': {
      color: '#172B4D',

    },
  }
`;

export const ContainerSides = styled.div`
  width: 52px;
  background: #0a1a5c;
  border-radius: 50%;
  height: 52px;
  z-index: 1000;

  position: absolute;
  top: -40%;

  display: flex;

  align-items: center;

  justify-content: center;

  div {
    background: #fff;
    width: 30px;
    height: 25px;

    border-radius: 5px;
  }
`;
