import { createContext, useCallback, useContext, useState } from 'react';
import { AdministratorUserControlContextInterface } from '../interface/context/AdministratorUserControlContext';
import { User } from '../interface/global/Users';
import { WorkShiftTypes } from '../interface/global/WorkShiftTransprot';

export const AdministratorUserControlContext =
  createContext<AdministratorUserControlContextInterface>(
    {} as AdministratorUserControlContextInterface
  );

export const AdministratorUserControlProvider: React.FC = ({ children }) => {
  const [selectionUserData, setSelectionUserData] = useState<User>();

  const setSelectionUser = useCallback(async (data: User) => {
    setSelectionUserData(data);
  }, []);

  const [selectionUsersData, setSelectionUsersData] = useState<User[]>(() => {
    const data = localStorage.getItem('@OnePlace: qrCodeLs');

    if (data) {
      return JSON.parse(data);
    }

    return [] as User[];
  });

  const setSelectionUsers = useCallback(async (data: User[]) => {
    localStorage.setItem('@OnePlace: qrCodeLs', JSON.stringify(data));
    setSelectionUsersData(data);
  }, []);

  const [selectionUsersTempData, setSelectionUsersTempData] = useState<User[]>(
    () => {
      const data = localStorage.getItem('@OnePlace: qrCodeLsTemp');

      if (data) {
        return JSON.parse(data);
      }

      return [] as User[];
    }
  );

  const setSelectionUsersTemp = useCallback(async (data: User[]) => {
    localStorage.setItem('@OnePlace: qrCodeLsTemp', JSON.stringify(data));
    setSelectionUsersTempData(data);
  }, []);

  const [showControlReserveData, setShowControlReserveData] =
    useState<boolean>(false);

  const setShowControlReserve = useCallback(async (data: boolean) => {
    setShowControlReserveData(data);
  }, []);

  const [reloadData, setReloadData] = useState<Date>(new Date());

  const setReload = useCallback(async (data: Date) => {
    setReloadData(data);
  }, []);

  const [dateToReserveData, setDateToReserveData] = useState<Date>(new Date());

  const setDateToReserve = useCallback(async (data: Date) => {
    setDateToReserveData(data);
  }, []);

  const [typeOfReserveData, setTypeOfReservesData] = useState<string>('avulse');

  const setTypeOfReserves = useCallback(async (data: string) => {
    setTypeOfReservesData(data);
  }, []);

  const [workShiftOfReserveData, setWorkShiftOfReserveData] =
    useState<WorkShiftTypes>({
      embark: 'ADM',
      disembark: 'ADM',
    });

  const setWorkShiftOfReserve = useCallback(async (data: WorkShiftTypes) => {
    setWorkShiftOfReserveData(data);
  }, []);

  const [unityData, setUnityData] = useState<string>('');

  const setUnity = useCallback(async (data: string) => {
    setUnityData(data);
  }, []);

  const [typesBusData, setTypesBusData] = useState<string>('embarkAdisembark');

  const setTypesBus = useCallback(async (data: string) => {
    setTypesBusData(data);
  }, []);

  const [filterTypesBusData, setFilterTypesBusData] =
    useState<string>('embark');

  const setFilterTypesBus = useCallback(async (data: string) => {
    setFilterTypesBusData(data);
  }, []);

  const [showPopUpOfUserData, setShowPopUpOfUserData] =
    useState<boolean>(false);

  const setShowPopUpOfUser = useCallback(async (data: boolean) => {
    setShowPopUpOfUserData(data);
  }, []);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <AdministratorUserControlContext.Provider
      value={{
        selectionUser: selectionUserData,
        setSelectionUser,
        selectionUsers: selectionUsersData,
        setSelectionUsers,
        selectionUsersTemp: selectionUsersTempData,
        setSelectionUsersTemp,
        showControlReserve: showControlReserveData,
        setShowControlReserve,
        reload: reloadData,
        setReload,
        workShiftOfReserve: workShiftOfReserveData,
        setWorkShiftOfReserve,
        typeOfReserve: typeOfReserveData,
        setTypeOfReserves,
        dateToReserve: dateToReserveData,
        setDateToReserve,
        unity: unityData,
        setUnity,
        typesBus: typesBusData,
        setTypesBus,
        filterTypesBus: filterTypesBusData,
        setFilterTypesBus,
        showPopUpOfUser: showPopUpOfUserData,
        setShowPopUpOfUser,
      }}
    >
      {children}
    </AdministratorUserControlContext.Provider>
  );
};

export function useAdministratorUserControl(): AdministratorUserControlContextInterface {
  return useContext(AdministratorUserControlContext);
}
