import { UnitySelect } from '../../../interface/global/AuthInterface';

export default function CheckUnity(unity: UnitySelect[]) {
  const transport = unity.filter((data) => {
    return data.haveTransport === true || data.haveParking === true;
  });

  const onlyBus = unity.filter((data) => {
    return data.haveTransport === true;
  });

  const workstation = unity.filter((data) => {
    return data.haveWorkstation === true;
  });
  const restaurant = unity.filter((data) => {
    return data.haveRestaurant === true;
  });

  return {
    transport: transport.length > 0,
    workstation: workstation.length > 0,
    restaurant: restaurant.length > 0,
    onlyBus: onlyBus.length > 0,
  };
}
