import React, { createContext, useCallback, useContext, useState } from 'react';
import { IOccupation } from '../components/transport/Car/interface';
import { TransportContextInterface } from '../interface/context/TransportContext';
import { WayToGoState } from '../interface/global/TransportBusInterface';
import {
  BoardState,
  CharteredEnd,
  IParkingReserve,
  ParkingReserveState,
  ParkingState,
  ReserveState,
  ReserveValeState,
  UserAddressState,
  WayState,
} from '../interface/global/TransportInterface';
import { TransportBusProvider } from './TransportBus';

export const TransportContext = createContext<TransportContextInterface>(
  {} as TransportContextInterface
);

const constOnePlaceBoardData = '@OnePlace: boardData';
const constOnePlaceParkingData = '@OnePlace: parkingDataExt';
const constOnePlaceGoingData = '@OnePlace: goingData';
const constOnePlaceReturningData = '@OnePlace: returningData';
const constOnePlaceParkingReserveData = '@OnePlace: parkingReserveData';

export const TransportProvider: React.FC = ({ children }) => {
  const [boardData, setBoardData] = useState<BoardState>(() => {
    const board = localStorage.getItem(constOnePlaceBoardData);

    if (board) {
      return { board: JSON.parse(board) };
    }

    return {} as BoardState;
  });

  const [confirmData, setConfirmData] = useState<boolean>(false);

  const [parkingData, setParkingData] = useState<ParkingState>(() => {
    const parking = localStorage.getItem(constOnePlaceParkingData);

    if (parking) {
      return { parking };
    }

    return {} as ParkingState;
  });

  const [wayData, setWayData] = useState<WayState>(() => {
    const going = localStorage.getItem(constOnePlaceGoingData);
    const returning = localStorage.getItem(constOnePlaceReturningData);

    if (going && returning) {
      return { going: JSON.parse(going), returning: JSON.parse(returning) };
    }

    return {} as WayState;
  });

  const [reserveData, setReserveData] = useState<ReserveState>({
    returningLine: undefined,
    returningStop: undefined,
  });

  const [reserveValeData, setReserveValeData] = useState<ReserveValeState>({
    returningLine: undefined,
    goingLine: undefined,
  });

  const [occupationData, setOccupationData] = useState<IOccupation>({
    parkingLotAvaliable: 0,
    parkingLotOccupied: 0,
    parkingLotReserve: 0,
  });

  const setOccupation = useCallback(async (confirm: IOccupation) => {
    setOccupationData(confirm);
  }, []);

  const [parkingReserveUserData, setParkingReserveUserData] =
    useState<IParkingReserve>();

  const setParkingReserveUser = useCallback(async (data: IParkingReserve) => {
    setParkingReserveUserData(data);
  }, []);

  const [parkingReserveData, setParkingReserveData] =
    useState<ParkingReserveState>(() => {
      const parkingReserve = localStorage.getItem(
        constOnePlaceParkingReserveData
      );

      if (parkingReserve) {
        const reserve: ParkingReserveState = JSON.parse(parkingReserve);
        reserve.endHour = new Date(reserve.endHour);
        reserve.startHour = new Date(reserve.startHour);

        return reserve;
      }
      return {} as ParkingReserveState;
    });

  const [userAddressData, setUserAddressData] = useState<UserAddressState>(
    () => {
      const data = localStorage.getItem('@OnePlace: userAddress');

      if (data) {
        return JSON.parse(data);
      }

      return {} as UserAddressState;
    }
  );

  const [charteredEndData, setCharteredEndData] = useState<CharteredEnd>({
    chartered: false,
  });

  const setBoard = useCallback(async (board) => {
    localStorage.setItem(constOnePlaceBoardData, JSON.stringify(board));

    setBoardData({ board });
  }, []);

  const setConfirm = useCallback(async (confirm: boolean) => {
    setConfirmData(confirm);
  }, []);

  const setParking = useCallback(async (parking) => {
    localStorage.setItem(constOnePlaceParkingData, parking);

    setParkingData({ parking });
  }, []);

  const setCharteredEnd = useCallback(async (chartered) => {
    setCharteredEndData({ chartered });
  }, []);

  const setWay = useCallback(async ({ going, returning }) => {
    localStorage.setItem(constOnePlaceGoingData, JSON.stringify(going));
    localStorage.setItem(constOnePlaceReturningData, JSON.stringify(returning));

    setWayData({ going, returning });
  }, []);

  const setReserve = useCallback(
    async ({
      goingStop,
      goingLine,
      returningStop,
      returningLine,
    }: ReserveState) => {
      setReserveData({ goingStop, goingLine, returningStop, returningLine });
    },
    []
  );

  const setReserveVale = useCallback(async (data: ReserveValeState) => {
    setReserveValeData(data);
  }, []);

  const [wayToGoData, setWayToGoData] = useState<WayToGoState>({ way: '' });

  const setWayToGo = useCallback(async (way) => {
    if (way !== '') {
      setWayToGoData({ way });
    } else {
      setWayToGoData({ way: '' });
    }
  }, []);

  const setParkingReserve = useCallback(async (parkingReserve) => {
    localStorage.setItem(
      constOnePlaceParkingReserveData,
      JSON.stringify(parkingReserve)
    );
    setParkingReserveData(parkingReserve);
  }, []);

  const setUserAddress = useCallback(async (data: UserAddressState) => {
    localStorage.setItem('@OnePlace: userAddress', JSON.stringify(data));

    setUserAddressData(data);
  }, []);

  const newTransport = useCallback(() => {
    localStorage.removeItem(constOnePlaceBoardData);
    localStorage.removeItem('@OnePlace: confirmData');
    localStorage.removeItem(constOnePlaceParkingData);
    localStorage.removeItem(constOnePlaceGoingData);
    localStorage.removeItem(constOnePlaceReturningData);
    localStorage.removeItem('@OnePlace: goingStopData');
    localStorage.removeItem('@OnePlace: goingLineData');
    localStorage.removeItem('@OnePlace: returningStopData');
    localStorage.removeItem('@OnePlace: returningLineData');
    localStorage.removeItem(constOnePlaceParkingReserveData);
    localStorage.removeItem('@OnePlace: busGoing');
    localStorage.removeItem('@OnePlace: charteredEnd');
    localStorage.removeItem('@OnePlace: busReturning');
    setReserveData({
      goingStop: undefined,
      goingLine: undefined,
      returningStop: undefined,
      returningLine: undefined,
    });
  }, []);

  return (
    <TransportContext.Provider
      value={{
        reserve: reserveData,
        parkingReserve: parkingReserveData,
        board: boardData.board,
        confirm: confirmData,
        parking: parkingData.parking,
        way: wayData,
        userAddress: userAddressData,
        charteredEnd: charteredEndData.chartered,
        setParking,
        setReserve,
        wayToGo: wayToGoData.way,
        setWayToGo,
        setParkingReserve,
        setWay,
        setBoard,
        setConfirm,
        newTransport,
        setUserAddress,
        setCharteredEnd,
        setReserveVale,
        reserveVale: reserveValeData,
        setOccupation,
        occupation: occupationData,
        parkingReserveUser: parkingReserveUserData,
        setParkingReserveUser,
      }}
    >
      <TransportBusProvider>{children}</TransportBusProvider>
    </TransportContext.Provider>
  );
};

export function useTransport(): TransportContextInterface {
  return useContext(TransportContext);
}
