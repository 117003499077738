import { useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useHistory, useParams } from 'react-router-dom';
import { AiFillHome } from 'react-icons/ai';
import { BsFillCalendarCheckFill } from 'react-icons/bs';
import { FaBus } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Container, ContainerSides } from './styles';
import { useAuth } from '../../../context/AuthContext';
import reservar from '../../../assets/icons/reservar.png';
import Drawer from '../drawer';
import CheckUnity from '../../../pages/FazerReserva/function/CheckUnity';

interface parm {
  id: string;
}

const ButtomSides: React.FC = () => {
  const { isDesktop, unitys } = useAuth();
  const history = useHistory();
  const [onTouch, setOnTouch] = useState(false);
  const [onTouch2, setOnTouch2] = useState(false);
  const [onTouch3, setOnTouch3] = useState(false);

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  const { id } = useParams<parm>();

  return (
    <>
      {!isDesktop && (
        <Container>
          <BottomNavigation
            showLabels
            sx={{ width: '100%' }}
            style={{ zIndex: 1000, paddingBottom: '15px', height: 'auto' }}
            value={id}
          >
            <BottomNavigationAction
              label="Inicio"
              onClick={() => {
                history.push('/home');
                setOnTouch(false);
                setOnTouch2(false);
                setOnTouch3(false);
              }}
              value="home"
              icon={<AiFillHome size={25} />}
            />
            <BottomNavigationAction
              label="Minhas reservas"
              style={onTouch3 ? { color: '#1E90FF' } : {}}
              onClick={() => {
                history.push('/MinhasReservas');
                setOnTouch(false);
                setOnTouch2(false);
                setOnTouch3(true);
              }}
              value="MinhasReservas"
              icon={<BsFillCalendarCheckFill size={25} />}
            />
            <BottomNavigationAction
              label="Reservar"
              className="reserve"
              onClick={() => {
                history.push('/Reservar');
                setOnTouch(false);
                setOnTouch2(false);
                setOnTouch3(false);
              }}
              value="reserv"
              icon={
                <>
                  <ContainerSides>
                    <img src={reservar} alt="reservar" />
                  </ContainerSides>
                  <br />
                  <br />
                </>
              }
            />
            {CheckUnity(unitys).onlyBus && (
              <BottomNavigationAction
                label="Consulta Itinerário"
                onClick={() => {
                  history.push('/Itinerary');
                  setOnTouch2(!onTouch2);
                  setOnTouch3(false);
                }}
                value="itinerary"
                icon={
                  onTouch2 ? (
                    <FaBus size={25} style={{ color: '#1E90FF' }} />
                  ) : (
                    <FaBus size={25} />
                  )
                }
              />
            )}

            <BottomNavigationAction
              label="Outros"
              style={onTouch ? { color: '#1E90FF', fontSize: '18px' } : {}}
              onClick={() => {
                setOnTouch(!onTouch);
                setOnTouch2(false);
                setOnTouch3(false);
              }}
              value="drawer"
              icon={
                onTouch ? (
                  <GiHamburgerMenu size={25} style={{ color: '#1E90FF' }} />
                ) : (
                  <GiHamburgerMenu size={25} />
                )
              }
            />
            {onTouch && (
              <Drawer
                cancel={() => {
                  setOnTouch(false);
                  setOnTouch2(false);
                  setOnTouch3(false);
                }}
              />
            )}
          </BottomNavigation>
        </Container>
      )}
    </>
  );
};

export default ButtomSides;
