import React from 'react';
import 'react-dropdown/style.css';
import 'react-day-picker/lib/style.css';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Routes from './routes';
import './styles/index.css';
import './i18n';
import 'moment/locale/pt-br';

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
