import { createContext, useCallback, useContext, useState } from 'react';
import { ReservesContextInterface } from '../interface/context/ReservesContext';
import { ReservesUserInterface } from '../interface/global/ReserveInterface';
import api from '../services/api';

export const ReservesContext = createContext<ReservesContextInterface>(
  {} as ReservesContextInterface
);

export const ReservesProvider: React.FC = ({ children }) => {
  const [TodayReservesData, setTodayReservesData] =
    useState<ReservesUserInterface>();

  const setTodayReserves = useCallback(async (data: ReservesUserInterface) => {
    setTodayReservesData(data);
  }, []);

  const checkTodayReserves = useCallback(async (date: Date) => {
    return api
      .get(`user/reserves`, { params: { date, fixed: false } })
      .then((response) => {
        setTodayReserves(response.data);
        return response.data;
      });
  }, []);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <ReservesContext.Provider
      value={{
        TodayReserves: TodayReservesData,
        setTodayReserves,
        checkTodayReserves,
      }}
    >
      {children}
    </ReservesContext.Provider>
  );
};

export function useReserves(): ReservesContextInterface {
  return useContext(ReservesContext);
}
